// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-screens-blog-list-blog-index-js": () => import("../src/components/screens/Blog/ListBlog/index.js" /* webpackChunkName: "component---src-components-screens-blog-list-blog-index-js" */),
  "component---src-components-screens-blog-index-js": () => import("../src/components/screens/Blog/index.js" /* webpackChunkName: "component---src-components-screens-blog-index-js" */),
  "component---src-components-screens-portfolio-index-js": () => import("../src/components/screens/Portfolio/index.js" /* webpackChunkName: "component---src-components-screens-portfolio-index-js" */),
  "component---src-components-screens-news-list-news-index-js": () => import("../src/components/screens/News/ListNews/index.js" /* webpackChunkName: "component---src-components-screens-news-list-news-index-js" */),
  "component---src-components-screens-news-detail-new-index-js": () => import("../src/components/screens/News/DetailNew/index.js" /* webpackChunkName: "component---src-components-screens-news-detail-new-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-capabilities-js": () => import("../src/pages/capabilities.js" /* webpackChunkName: "component---src-pages-capabilities-js" */),
  "component---src-pages-capabilities-html-css-js": () => import("../src/pages/capabilities/html-css.js" /* webpackChunkName: "component---src-pages-capabilities-html-css-js" */),
  "component---src-pages-capabilities-joomla-js": () => import("../src/pages/capabilities/joomla.js" /* webpackChunkName: "component---src-pages-capabilities-joomla-js" */),
  "component---src-pages-capabilities-jquery-ajax-js": () => import("../src/pages/capabilities/jquery-ajax.js" /* webpackChunkName: "component---src-pages-capabilities-jquery-ajax-js" */),
  "component---src-pages-capabilities-magento-js": () => import("../src/pages/capabilities/magento.js" /* webpackChunkName: "component---src-pages-capabilities-magento-js" */),
  "component---src-pages-capabilities-nginx-js": () => import("../src/pages/capabilities/nginx.js" /* webpackChunkName: "component---src-pages-capabilities-nginx-js" */),
  "component---src-pages-capabilities-php-mysql-js": () => import("../src/pages/capabilities/php-mysql.js" /* webpackChunkName: "component---src-pages-capabilities-php-mysql-js" */),
  "component---src-pages-capabilities-pts-illus-js": () => import("../src/pages/capabilities/pts-illus.js" /* webpackChunkName: "component---src-pages-capabilities-pts-illus-js" */),
  "component---src-pages-capabilities-varnish-js": () => import("../src/pages/capabilities/varnish.js" /* webpackChunkName: "component---src-pages-capabilities-varnish-js" */),
  "component---src-pages-capabilities-wordpress-js": () => import("../src/pages/capabilities/wordpress.js" /* webpackChunkName: "component---src-pages-capabilities-wordpress-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-js": () => import("../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-dedicated-support-js": () => import("../src/pages/services/dedicated-support.js" /* webpackChunkName: "component---src-pages-services-dedicated-support-js" */),
  "component---src-pages-services-outsourcing-js": () => import("../src/pages/services/outsourcing.js" /* webpackChunkName: "component---src-pages-services-outsourcing-js" */),
  "component---src-pages-services-web-design-js": () => import("../src/pages/services/web-design.js" /* webpackChunkName: "component---src-pages-services-web-design-js" */),
  "component---src-pages-services-web-development-js": () => import("../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-slide-show-index-js": () => import("../src/pages/SlideShow/index.js" /* webpackChunkName: "component---src-pages-slide-show-index-js" */),
  "component---src-pages-work-with-us-js": () => import("../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */)
}

